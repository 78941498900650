// extracted by mini-css-extract-plugin
export var discoverWorksItem = "cR_t8";
export var discoverWorksItem__arrowLink = "cR_vm";
export var discoverWorksItem__bg = "cR_vc";
export var discoverWorksItem__clientImg = "cR_vq";
export var discoverWorksItem__clientName = "cR_vr";
export var discoverWorksItem__clientPosition = "cR_vs";
export var discoverWorksItem__gradientAfricar = "cR_vH";
export var discoverWorksItem__gradientBabel = "cR_vL";
export var discoverWorksItem__gradientCaker = "cR_vz";
export var discoverWorksItem__gradientDv = "cR_vG";
export var discoverWorksItem__gradientImpact = "cR_vv";
export var discoverWorksItem__gradientIx = "cR_vF";
export var discoverWorksItem__gradientMalleni = "cR_vw";
export var discoverWorksItem__gradientPmk = "cR_vC";
export var discoverWorksItem__gradientPmkDesktop = "cR_vD";
export var discoverWorksItem__gradientRew = "cR_vt";
export var discoverWorksItem__gradientTimebot = "cR_vK";
export var discoverWorksItem__gradientTradeaboat = "cR_vx";
export var discoverWorksItem__gradientTradervs = "cR_vy";
export var discoverWorksItem__gradientVaria = "cR_vJ";
export var discoverWorksItem__gradientWowner = "cR_vB";
export var discoverWorksItem__header = "cR_t9";
export var discoverWorksItem__imageHover = "cR_vb";
export var discoverWorksItem__mobileImage = "cR_vn";
export var discoverWorksItem__results = "cR_vg";
export var discoverWorksItem__results_head = "cR_vh";
export var discoverWorksItem__results_num = "cR_vj";
export var discoverWorksItem__results_text = "cR_vk";
export var discoverWorksItem__results_textOnly = "cR_vl";
export var discoverWorksItem__review = "cR_vp";
export var discoverWorksItem__title = "cR_vd";
export var discoverWorksItem__wrapper = "cR_vf";