// extracted by mini-css-extract-plugin
export var servicesDevExpertiseCompany = "c9_ys";
export var servicesDevExpertiseCompany__card = "c9_yt";
export var servicesDevExpertiseCompany__emoji = "c9_yw";
export var servicesDevExpertiseCompany__maxWidth140 = "c9_yy";
export var servicesDevExpertiseCompany__maxWidth180 = "c9_yz";
export var servicesDevExpertiseCompany__maxWidth190 = "c9_yB";
export var servicesDevExpertiseCompany__maxWidth270 = "c9_yC";
export var servicesDevExpertiseCompany__maxWidth310 = "c9_yD";
export var servicesDevExpertiseCompany__text = "c9_yx";
export var servicesDevExpertiseCompany__title = "c9_yv";