// extracted by mini-css-extract-plugin
export var insuranceBenefitsSection = "C_fm";
export var insuranceBusinessesSection = "C_fk";
export var insuranceCaseStudySection = "C_fp";
export var insuranceClientQuotesSection = "C_fx";
export var insuranceDevExpertiseSection = "C_ft";
export var insuranceDevServicesIconSection = "C_fj";
export var insuranceOurAchievementsSection = "C_fw";
export var insuranceProcessSection = "C_fl";
export var insuranceRequirementsSection = "C_fq";
export var insuranceSkilledSection = "C_fv";
export var insuranceSuccessStoriesSection = "C_fr";
export var insuranceTechStackSection = "C_fn";
export var insuranceWhyHireSection = "C_fs";