// extracted by mini-css-extract-plugin
export var servicesTechStack__item = "f1_Ps";
export var servicesTechStack__itemWrapper = "f1_Pr";
export var servicesTechStack__name = "f1_Pt";
export var servicesTechStack__name_chromedevtools = "f1_PC";
export var servicesTechStack__name_digitalocean = "f1_Pz";
export var servicesTechStack__name_elasticsearch = "f1_Px";
export var servicesTechStack__name_gitlab = "f1_PB";
export var servicesTechStack__name_googleCloudPlatform = "f1_PF";
export var servicesTechStack__name_googleDocs = "f1_PD";
export var servicesTechStack__name_googleanalytics = "f1_Pv";
export var servicesTechStack__name_prometheus = "f1_Pw";
export var servicesTechStack__name_ror = "f1_Py";